import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Box, Divider } from '@mui/material';
import ReviewRow, { ReviewProps } from './ReviewRow';
import { Review } from "../api/generated";

interface ReviewsListProps {
  reviews: Review[] | undefined;
  pageSize?: number;
}

const ReviewsList: React.FC<ReviewsListProps> = ({ reviews, pageSize = 5 }) => {

  const [page, setPage] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Check for undefined customReviews after useState
  if (!reviews) {
    return null;
  }

  const pageContent = reviews.slice((page - 1) * pageSize, page * pageSize);

  return (
      <Box>
        {
          pageContent.map((review, index) => (
              <React.Fragment key={index}>
                <ReviewRow {...review} />
                {(index !== pageContent.length - 1) && <Divider />}
              </React.Fragment>
          ))
        }
        <Stack spacing={2} alignItems="center" mt={2}>
          {reviews.length > 1 && <Pagination count={Math.ceil(reviews.length / pageSize)} page={page} onChange={handleChange} />}
        </Stack>
      </Box>
  );
}

export default ReviewsList;
