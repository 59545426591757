import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Review} from "../generated";
import ReviewService from "./ReviewService";
import {QueryError} from "../QueryError";
import {useToast} from "../../components/toast/useToast";



const ReviewMutation = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const addReview = useMutation({
        mutationFn: (review: Review) => {
            return ReviewService.postReview(review);
        },
        onError: (error: QueryError) => {
            console.error("ERROR", error);
            showToast("Hodnocení se nepodařilo uložit.", "error");
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['reviews'] });
            console.log("success", data, variables, context);
            showToast("Hodnocení uloženo.", "success");
        }
    });

    // Return the mutation function for external use
    return {
        addReview
    };
};

export default ReviewMutation;
