import * as React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
    const year = new Date().getFullYear();

    return (
        <Box sx={{ p: 3, backgroundColor: '#f8f8f8', mt: 'auto', marginTop: 5 }}>
            <Typography variant="body2" color="text.secondary" align="center">
                {year}
                {' © Tagli.cz '}
            </Typography>
        </Box>
    );
};

export default Footer;