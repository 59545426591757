import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReviewRow from "./components/ReviewRow";
import ReviewsList from "./components/ReviewsList";
import AddReview from "./components/AddReview";
import ReviewsContainer from "./components/ReviewsContainer";
import Footer from "./components/Footer";
import ToastProvider from "./components/toast/ToastProvider";
import {BrowserRouter} from "react-router-dom";

function App() {



  return (
    <div className="App">
        <BrowserRouter basename={"/"}>
            <ToastProvider>
                <ReviewsContainer />
                <Footer />
            </ToastProvider>
        </BrowserRouter>
    </div>
  );
}

export default App;
