import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Grid, Stack} from '@mui/material';
import {Review} from "../api/generated";
import {PhotoProvider, PhotoView} from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';

export interface ReviewProps {
  name: string;
  stars: number;
  text: string;
  date: string;
}

const ReviewRow: React.FC<Review> = ({ name, rating, reviewText, reviewImageUrl, createdAt }) => (
    <Box p={2}>
        <Stack pt={2} pb={2}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                <Grid item xs={12} sm={4} container justifyContent="flex-start">
                    <Typography variant="h6" style={{ textAlign: 'left' }}>{name}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    <Box component="fieldset" borderColor="transparent">
                        <Rating name="read-only" value={rating} readOnly />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} container justifyContent="flex-end">
                    {createdAt && (
                        <Typography variant="caption" display="block" style={{ textAlign: 'right' }}>
                            {new Date(createdAt).toLocaleString()}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} container justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%">
                        <Typography align="justify">{reviewText}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4} container justifyContent="center">
                    {reviewImageUrl && (
                        <PhotoProvider>
                            <PhotoView src={reviewImageUrl}>
                                <img src={reviewImageUrl} alt="Review" style={{ maxWidth: '100%' }} />
                            </PhotoView>
                        </PhotoProvider>
                    )}
                </Grid>
            </Grid>
        </Stack>
    </Box>




);

export default ReviewRow;