import {axiosInstance, handleRequest} from "../axios";
import {REVIEWS_URL} from "../url";
import { Review } from "../generated"


const getAllReviews = async (): Promise<Review[]> => {
    return handleRequest(() => axiosInstance.get<Review[]>(REVIEWS_URL));
}

const postReview = (review:Review) => {
    return handleRequest(() => axiosInstance.post(REVIEWS_URL, review));
}

const ReviewService = {
    getAllReviews,
    postReview
}


export default ReviewService;